
.script-main {
    display: flex;
    flex-direction: column;
    height: 100%;
    .script-item{
        display: flex;
        flex-direction: column;
        height:calc(100vh - 235px);
    }
    .coverImg{
        width:99px;
        height: 57px;
        display: inline-block;
        img{
            width:99px;
            height: 57px;
        }
    }
    .btn-blue-script{
        color:#1E33E4;
        font-size: 14px;
    }
    .btn-red-script{
        color:#F90B32;
        font-size: 14px;
    }
}


.admin-top{
    ::v-deep .el-tabs{
        width: 100%;
    }
    ::v-deep.el-tabs__nav-wrap::after{
        background-color: transparent;
    }
    ::v-deep.el-tabs__item.is-active {
        color: #574DED;
    }
    ::v-deep.el-tabs__item:hover {
        color: #1D33E1;
        cursor: pointer;
    }
    .content{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;

        .item{
            .btn-red-script{
                display: inline-block;
                color: #F90B32 ;
                font-size: 14px;
            }
            .btn-red-script:hover,
            .btn-red-script:focus,
            .btn-red-script.current {
                color: #dc0012;
            }
        }
        .script-search{
            width: 270px;
            height: 30px;
            background: #FFFFFF;
            border: 1px solid #594FEE;
            border-radius: 5px;
            ::v-deep.el-input--small{
                .el-input__inner{
                    height: 30px;
                }
            }
        }
    }
}
::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
    background-color: transparent;
}
::v-deep.el-table--medium th{
    padding: 16px 4px;
}

::v-deep.el-tabs__active-bar{
    background-color:transparent;
}
::v-deep.el-checkbox__input.is-checked .el-checkbox__inner, ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #1E33E3;
    border-color: #1E33E3;
}
